import React, { createContext, useCallback, useEffect, useRef } from 'react';
import Head from 'next/head';
import {
  Placeholder,
  getPublicUrl,
  LayoutServiceData,
  Field,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { initializeTagManager } from './lib/utils/gtmService';
import Scripts from './Scripts';
import useDisclaimerModal from './lib/hooks/useDisclaimerModal';
import { IStickyData } from './components/StickyFooter/StickyFooter';
import { RootState, store } from '../src/store/store';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { AppDispatch } from '../src/store/store';
import { addQuaryParams } from '../src/features/queryParamsSlice';
import { ParsedUrlQuery } from 'querystring';
import AppBar from '@mui/material/AppBar';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { StAppBarWrapper } from './components/DTP/HeaderNavigation/HeaderNavigation.styles';
import useSitecoreEngage from './lib/hooks/useSitecoreEngage';
import useGeoLocation from './lib/hooks/useGeoLocation';
import useCDPDataUpdateOnHomePageLoad from './lib/hooks/useCDPDataUpdateOnHomePageLoad';
import * as CdpService from './services/CdpService';
import { addGlobalSettings } from './features/globalSettingSlice';
import { addpotentialId } from './features/potentialParticipantDetailsSlice';
import useUpdateCurrentJourney from './lib/hooks/useUpdateCurrentJourney';
import useUpdateGTMForOutcomePages from './lib/hooks/useUpdateGTMForOutcomePages';
// import useSendEmail from './lib/hooks/useSendEmail';
import useMyProfile from './lib/hooks/useMyProfile';
import { post } from './lib/utils/axiosFetcher';
import useQueryParam from './lib/hooks/useQueryParam';
import isEmpty from 'lodash/isEmpty';
import NoScriptBanner from './components/shared/NoScriptBanner/NoScriptBanner';
import getCallCenterNumber from './store/selectors/getCallCenterNumber';
import {
  deleteGAandAdvertisementCookies,
  isAcceptCookieOptinAndActive,
} from './lib/utils/cookieHelpers';
import { pickBy } from 'lodash';
import useAuthentication from './lib/hooks/useAuthentication';
import useAuthIdleTimer from './lib/hooks/useIdleTimer';
import { updateIsEmailLinkExpired } from './features/crProfileSlice';
import { CR_SITE } from './lib/constant';
import useLastVisitedComponent from './lib/hooks/useLastVisitedComponent';
import useUpdateUCPMJobRecord from './lib/hooks/useUpdateUCPMJobRecord';
// Prefix public assets with a public URL to enable compatibility with Sitecore editors.
// If you're not supporting Sitecore editors, you can remove this.
import dynamic from 'next/dynamic';
import PromptIdleTimeoutModal from './components/MyProfile/Modal/PromptIdleTimeoutModal';
import useCRQueryParam from './lib/hooks/useCRQueryParam';
import { getCookie, setCookie } from 'cookies-next';
import { getQuery } from './lib/utils/common';

const ManageCookie = dynamic(() => import('./components/Cookie/ManageCookie/ManageCookie'), {
  ssr: false,
});
const CookieBanner = dynamic(() => import('./components/Cookie/CookieBanner/CookieBanner'), {
  ssr: false,
});
const CRModal = dynamic(() => import('./components/CR/CRModal'), {
  ssr: false,
});
const CustomModal = dynamic(() => import('./components/shared/CustomModal/CustomModal'), {
  ssr: false,
});
const DisclaimerModal = dynamic(() => import('./components/DTP/DisclaimerModal/DisclaimerModal'), {
  ssr: false,
});
const StickyFooter = dynamic(() => import('./components/StickyFooter/StickyFooter'), {
  ssr: false,
});
const SkipToMainContent = dynamic(() => import('./components/A11y/SkipToMainContent'), {
  ssr: false,
});
const Loader = dynamic(() => import('./components/shared/Loader/Loader'), {
  ssr: false,
});
const IdleSignOutModal = dynamic(() => import('./components/MyProfile/Modal/IdleSignOutModal'), {
  ssr: false,
});

const publicUrl = getPublicUrl();

interface LayoutProps {
  layoutData: LayoutServiceData;
}

interface RouteFields {
  [key: string]: Field<string | boolean | number>;
  isOutcomePage: Field<boolean>;
}

interface ModalContextProps {
  open: boolean;
  openModal: (param: any) => void;
  closeModal: () => void;
  link?: any;
}

interface IModalDataProps {
  isDisclaimerEnable: Field<boolean>;
  disclaimerHeading: Field<string>;
  disclaimerDescription: Field<string>;
  cancelText: Field<string>;
  continueText: Field<string>;
  ariaLabel: Field<string>;
}

interface PageQuery extends ParsedUrlQuery {
  paths: any;
  [key: string]: string;
}

export const modalContext = createContext<ModalContextProps>({} as ModalContextProps);

// Sometimes we want to run parent effects before those of the children Eg when creating user, or binding document listners.
// By passing the effect to the first child, it will run before any effects by later children
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function Effect({ effect }: any): null {
  useEffect(() => effect?.(), [effect]);
  return null;
}

const Layout = ({ layoutData }: LayoutProps): JSX.Element => {
  const { route, context } = layoutData.sitecore;
  const googleTagManagerCode = (context?.siteSettings as any)?.googleTagManagerCode as any;
  const stickyFooter = context?.stickyFooter as IStickyData;
  const DisclaimerData = context?.disclaimerPopup as IModalDataProps;
  const journeyType = useSelector((state: RootState) => state?.cdpData?.journeyType);
  const searchQueryData = useSelector((state: RootState) => state?.queryParams);
  const globalConfig = useSelector((state: RootState) => state?.globalConfig);
  const gtmId = (googleTagManagerCode as Field<string>)?.value || '';
  const { open, openModal, closeModal, link } = useDisclaimerModal(
    DisclaimerData?.isDisclaimerEnable?.value
  );
  const isHeaderExist = !isEmpty(route?.placeholders['jss-header']);
  const isFooterExist = !isEmpty(route?.placeholders['jss-footer']);
  const isScreenerLandingPageEnabled = (context?.siteSettings as any)?.enableScreenerLandingPage
    ?.value;
  const flatCss = (context?.siteSettings as any)?.flatCss?.value;
  const isSubHeader: any =
    (route?.placeholders['jss-header'] as any)?.findIndex(
      (item: any) =>
        item?.componentName === 'HeaderNavigation' && item?.fields?.isSubHeader?.value === true
    ) > -1
      ? true
      : false;
  const sourceApp = (context?.siteSettings as any)?.source?.fields?.name?.value;
  const pageContainerRef = useRef<HTMLElement>(null);
  const fields = route?.fields as RouteFields;
  const isHomePage = (route?.fields?.isHomePage as Field<boolean>)?.value;
  const isStickyFooterEnabled = (route?.fields?.isStickyFooter as Field<boolean>)?.value;
  const router = useRouter();
  const removeQueryParamList = (context?.siteSettings as any)?.RemoveQueryParamList?.value?.split(
    '&'
  );
  const retainQueryParamList = (context?.siteSettings as any)?.RetainQueryParamList?.value?.split(
    '&'
  );

  const siteName = context?.site?.name;

  const dispatch = useDispatch<AppDispatch>();
  const theme = useTheme();
  // Start: Code to add participant details in Database
  const potentialParticipantDetailsFromStore = useSelector(
    (state: RootState) => state.potentialParticipantDetails
  );
  // const { setJourneyModal, showJourneyModal } = useJourneyModal({});
  const { isCDPScriptLoaded, triggerStartOver } = useSitecoreEngage(
    {
      clientKey: process.env.NEXT_PUBLIC_CDP_CLIENT_KEY || 'de665c8ff4f47bfd6e5f7313baff0442',
      targetURL:
        process.env.NEXT_PUBLIC_CDP_API_TARGET_ENDPOINT || 'https://api-engage-eu.sitecorecloud.io',
      pointOfSale: context?.site?.name,
      cookieDomain: '',
      cookieExpiryDays: 365,
      forceServerCookieMode: false,
      includeUTMParameters: true,
      webPersonalization: true,
      scriptUrl:
        process.env.NEXT_PUBLIC_SITECORE_ENGAGE ||
        'https://d1mj578wat5n4o.cloudfront.net/sitecore-engage-v.1.3.0.min.js',
    },
    route
  );
  const { potentialParticipant_ID, potentialId } = potentialParticipantDetailsFromStore;

  useAuthentication();
  const {
    showLogOutModal,
    setLogOutModal,
    handleIdleLogout,
    promptBeforeLogout,
    continueSession,
    getRemainingTime,
  } = useAuthIdleTimer();
  useGeoLocation(); // This hook is for geo location
  useQueryParam();
  useCRQueryParam();
  useLastVisitedComponent();
  useCDPDataUpdateOnHomePageLoad(isCDPScriptLoaded, context, router, triggerStartOver); //TODO

  const manageQueryParams = (params = {}) => {
    const url = (window as any)?.location?.origin + (window as any)?.location?.pathname;
    if (!isEmpty(params)) {
      const queryinfo = pickBy(
        getQuery(params, retainQueryParamList, removeQueryParamList),
        (value) => !!value?.length
      );
      router.replace(
        {
          pathname: url,
          query: queryinfo,
        },
        undefined,
        { shallow: true }
      );
    }
  };

  // const manageCommonKeys = async () => {
  //   const { getQuery } = await import('./lib/utils/common');
  //   const { query } = router;
  //   const queryParamsData = pickBy(getQuery(searchQueryData), (value) => !!value?.length);
  //   const commonKeys = intersection(Object.keys(queryParamsData), Object.keys(query));
  //   const commonObj = pick(queryParamsData, commonKeys);
  //   if (!isEmpty(commonObj)) {
  //    dispatch(removedQuaryParams(commonObj as any));
  //  }
  //   return commonKeys;
  // };

  //language cookie creation
  useEffect(() => {
    const languageCookie = getCookie('language');
    if (!languageCookie) {
      const cookie_expiry = new Date();
      cookie_expiry.setDate(cookie_expiry.getDate() + 10e5);
      setCookie('language', router.locale, { secure: true, expires: cookie_expiry });
    }
  }, []);

  useEffect(() => {
    const languageCookie = getCookie('language');
    if (!languageCookie) {
      const cookie_expiry = new Date();
      cookie_expiry.setDate(cookie_expiry.getDate() + 10e5);
      setCookie('language', router.locale, { secure: true, expires: cookie_expiry });
    }
  }, []);

  useEffect(() => {
    dispatch(
      addGlobalSettings({
        tenantKey: (context?.siteSettings as any)?.tenantKey?.value,
        journeyID: journeyType,
        siteName: context?.site?.name,
      })
    );
    dispatch(addQuaryParams(router?.query as PageQuery));
  }, [dispatch, router?.query, journeyType]);

  const addParticipantSave = async () => {
    try {
      const { data: response } = await post('/Participant/Add', {
        ...potentialParticipantDetailsFromStore,
        callCenterNumber: getCallCenterNumber(context)?.value,
      });
      return response;
    } catch (_e) {}
  };

  const { mutate: createParticipant } = useMutation('add-participant', addParticipantSave, {
    onSuccess: async (data) => {
      dispatch(addpotentialId(data?.potentialId));
      if (globalConfig?.isSetGlobalSetting) {
        const queryObj = { ...searchQueryData };
        manageQueryParams(queryObj);
        //   const commonKeyList = await manageCommonKeys();
        //  if (!isEmpty(commonKeyList)) {
        //     const queryObj = { ...searchQueryData };
        //      commonKeyList.forEach((key) => {
        //       delete queryObj?.[key];
        //     });
        //   }
      }
    },
    onError: () => undefined,
    onSettled: () => {
      //   queryClient.invalidateQueries(''); // invalidate any get query for screener data
    },
  });
  // End: Code to add participant details in Database

  useEffect(() => {
    const initializeCookie = async () => {
      //Initialize Google Tag manger
      if (
        gtmId &&
        isAcceptCookieOptinAndActive('Non-Essential Cookies') &&
        siteName !== CR_SITE &&
        isHomePage
      ) {
        await deleteGAandAdvertisementCookies();
        initializeTagManager(gtmId, siteName === CR_SITE);
      }
    };
    if (!isEditorActive()) {
      initializeCookie();
    }
  }, []);

  useUpdateUCPMJobRecord(route);
  useUpdateCurrentJourney(route, isCDPScriptLoaded);
  useUpdateGTMForOutcomePages(route);
  // useSendEmail(route, isCDPScriptLoaded);
  useMyProfile(route, sourceApp);

  // useEffect(() => {
  //   //Initial call on component mount
  //   dispatch(CdpService.refreshCDPToken());

  //   // Call to refresh token every 15 minutes
  //   const interval = setInterval(() => {
  //     dispatch(CdpService.refreshCDPToken());
  //   }, 14 * 60 * 1000);

  //   // Clear inteval on component unmount
  //   return () => clearInterval(interval);
  // }, [dispatch]);

  useEffect(() => {
    dispatch(addQuaryParams(router?.query as PageQuery));
    if (isCDPScriptLoaded) {
      CdpService.pageView();
    }
  }, [dispatch, router?.query, isCDPScriptLoaded]);

  useEffect(() => {
    //Storing store data from localstorage to redux store by dispatching REHYDRATE action
    store.__persistor.persist();
  }, []);

  // Start: Code to add participant details in Database
  const stableEffect = useCallback(() => {
    if (
      potentialParticipant_ID !== '' &&
      potentialParticipant_ID !== null &&
      isHomePage &&
      potentialId == ''
    ) {
      createParticipant();
    }
  }, [potentialParticipant_ID, potentialId]);
  // End: Code to add participant details in Database

  const convertToHtml = (html: any) => {
    if (typeof document !== 'undefined') {
      const test = document && document.createElement('metatags');
      let nodes: any = [],
        filteredNodes: any = [];
      test.innerHTML = html;
      nodes = [].slice.call(test.childNodes);
      filteredNodes = nodes.filter((node: any) => node?.nodeName != '#text');

      return filteredNodes;
    } else {
      return [];
    }
  };

  const metaTags: any[] = convertToHtml(fields?.metaTags?.value as string);
  const facebookMetaTags: any[] = convertToHtml(fields?.facebookMetaTags?.value as string);
  const twitterMetaTags: any[] = convertToHtml(fields?.twitterMetaTags?.value as string);
  const socialMediaAnalyticsMetaTags: any[] = convertToHtml(
    fields.socialMediaAnalyticsMetaTags?.value as string
  );
  const linkExpiredModelPopup: any = route?.fields?.linkExpiredModelPopup;
  const expiredModalDescription = linkExpiredModelPopup?.fields?.description;
  const expiredModallink = linkExpiredModelPopup?.fields?.link;
  const expiredModallinkText = linkExpiredModelPopup?.fields?.linkText;
  const expiredModalTitle = linkExpiredModelPopup?.fields?.title;
  const usePageUrl = linkExpiredModelPopup?.fields?.usePageUrl;
  const isEmailLinkExpired = useSelector((state: RootState) => state.crProfile.isEmailLinkExpired);

  const handleExpireModalContinue = async () => {
    await dispatch(updateIsEmailLinkExpired(false));
    if (!usePageUrl?.value) router.push(expiredModallink?.value?.href);
  };
  const handleCloseModal = async () => {
    const { PROFILE_VERIFY_TOKEN_KEY } = await import('./lib/utils/common');
    const myProfileVerificationToken = sessionStorage.getItem(PROFILE_VERIFY_TOKEN_KEY);
    if (!!myProfileVerificationToken) {
      dispatch(updateIsEmailLinkExpired(false));
    } else {
      dispatch(updateIsEmailLinkExpired(false));
      if (!usePageUrl?.value) router.push(expiredModallink?.value?.href);
    }
  };

  if (isEmailLinkExpired && !!linkExpiredModelPopup) {
    return (
      <CRModal
        open={isEmailLinkExpired}
        title={expiredModalTitle?.value}
        confirmButton={{ name: expiredModallinkText?.value }}
        handleClose={handleCloseModal}
        handleContinue={handleExpireModalContinue}
      >
        <Typography sx={{ color: theme.palette.neutral8.main }}>
          {expiredModalDescription?.value}
        </Typography>
      </CRModal>
    );
  }

  return (
    <>
      <Scripts />
      <Head>
        {/* Restore dataLayerClone if it exists */}
        {gtmId &&
          !context?.pageEditing &&
          ((isAcceptCookieOptinAndActive('Non-Essential Cookies') && siteName !== CR_SITE) ||
            siteName === CR_SITE) && (
            <script
              id="data-layer-restore"
              dangerouslySetInnerHTML={{
                __html: `
            if (window.dataLayerClone) {
              window.dataLayer = window.dataLayerClone;
            }
          `,
              }}
            />
          )}
        {gtmId && !context?.pageEditing && (
          <script
            id="data-layer-consent"
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              CookieModalAnalytics: 'on'
            });
          `,
            }}
          />
        )}

        {gtmId &&
          !context?.pageEditing &&
          ((isAcceptCookieOptinAndActive('Non-Essential Cookies') && siteName !== CR_SITE) ||
            siteName === CR_SITE) && (
            <script
              dangerouslySetInnerHTML={{
                __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${gtmId}');
              `,
              }}
            />
          )}

        <meta charSet="UTF-8"></meta>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"></meta>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge"></meta>
        {/* Normal meta tags */}
        {metaTags.length > 0 &&
          metaTags?.map((data: any, index: number) => {
            if (data.nodeName === 'META') {
              if (data.name) {
                return <meta key={index} name={data.name} content={data.content} />;
              } else {
                return (
                  <meta
                    key={index}
                    property={data.attributes.property.nodeValue}
                    content={data.content}
                  />
                );
              }
            } else {
              return (
                <link
                  key={`link-meta-${index}`}
                  rel={data.rel}
                  type={data.type}
                  title={data.title}
                  href={data.href}
                />
              );
            }
          })}
        {/* Facebook meta tags */}
        {facebookMetaTags.length > 0 &&
          facebookMetaTags?.map((data: any, index: number) => {
            if (data.nodeName === 'META') {
              if (data.name) {
                return <meta key={index} name={data.name} content={data.content} />;
              } else {
                return (
                  <meta
                    key={index}
                    property={data.attributes.property.nodeValue}
                    content={data.content}
                  />
                );
              }
            } else {
              return (
                <link
                  key={`link-facebook-${index}`}
                  rel={data.rel}
                  type={data.type}
                  title={data.title}
                  href={data.href}
                />
              );
            }
          })}
        {/* Twitter meta tags */}
        {twitterMetaTags.length > 0 &&
          twitterMetaTags?.map((data: any, index: number) => {
            if (data.nodeName === 'META') {
              if (data.name) {
                return <meta key={index} name={data.name} content={data.content} />;
              } else {
                return (
                  <meta
                    key={index}
                    property={data.attributes.property.nodeValue}
                    content={data.content}
                  />
                );
              }
            } else {
              return (
                <link
                  key={`link-twitter-${index}`}
                  rel={data.rel}
                  type={data.type}
                  title={data.title}
                  href={data.href}
                />
              );
            }
          })}
        {/* Social Media Analytics meta tags */}
        {socialMediaAnalyticsMetaTags.length > 0 &&
          socialMediaAnalyticsMetaTags?.map((data: any, index: number) => {
            if (data.nodeName === 'META') {
              if (data.name) {
                return <meta key={index} name={data.name} content={data.content} />;
              } else {
                return (
                  <meta
                    key={index}
                    property={data.attributes.property.nodeValue}
                    content={data.content}
                  />
                );
              }
            } else {
              return (
                <link
                  key={`link-analytics-${index}`}
                  rel={data.rel}
                  type={data.type}
                  title={data.title}
                  href={data.href}
                />
              );
            }
          })}
        <meta name="description" content={fields?.description?.value?.toString()}></meta>
        <meta name="keywords" content={fields?.keywords?.value?.toString()}></meta>
        <title>{fields?.pageTitle?.value?.toString()}</title>
        <link rel="icon" href={`${publicUrl}/favicon.png`} />
      </Head>
      <style jsx global>
        {`
          ${flatCss}
        `}
      </style>
      <SkipToMainContent />
      <modalContext.Provider value={{ open, openModal, closeModal, link }}>
        <DisclaimerModal modalData={DisclaimerData} />
        <Effect effect={stableEffect} />
        {(() => {
          if (
            isStickyFooterEnabled &&
            isScreenerLandingPageEnabled !== undefined &&
            !isScreenerLandingPageEnabled
          ) {
            return <StickyFooter stickyData={stickyFooter} />;
          }
        })()}
        {route && isHeaderExist && (
          <div>
            <StAppBarWrapper isSubHeader={isSubHeader}>
              <AppBar
                position={'fixed'}
                sx={{
                  bgcolor: 'appBarColor.main',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                  [theme.breakpoints.up('md')]: {
                    position:
                      isEditorActive() || context?.pageState === 'preview' ? 'static' : 'fixed',
                  },
                  boxShadow: 'unset',
                  borderBottom: '1px solid #d9d9d9',
                }}
              >
                <Placeholder name="jss-header" rendering={route} />
              </AppBar>
              <Toolbar />
            </StAppBarWrapper>
          </div>
        )}

        {/* root placeholder for the app, which we add components to using route data */}
        {/* <SiteSelection /> */}
        <noscript>
          <NoScriptBanner
            noScriptRichText={(context?.noScriptMessage as any)?.noScriptMessageBody}
          />
        </noscript>

        {gtmId && (
          <noscript>
            <iframe
              src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
              height="0"
              width="0"
              style={{ display: 'none', visibility: 'hidden' }}
            ></iframe>{' '}
          </noscript>
        )}

        <main className="container" ref={pageContainerRef}>
          {route && (
            <Placeholder
              name="jss-main"
              rendering={route}
              pageContainerRef={pageContainerRef}
              isFooterExist={isFooterExist}
            />
          )}
        </main>
        {!isEditorActive() && (
          <CookieBanner acceptGACookie={(): void => initializeTagManager(gtmId)} gtmId={gtmId} />
        )}

        <Loader />
        {isFooterExist && (
          <footer id="footer">
            {route && <Placeholder name="jss-footer" rendering={route} />}
          </footer>
        )}
      </modalContext.Provider>
      <CustomModal />
      <ManageCookie />
      {showLogOutModal && (
        <IdleSignOutModal
          modal={{
            open: showLogOutModal,
            onClose: () => setLogOutModal(false),
          }}
        />
      )}
      {promptBeforeLogout && (
        <PromptIdleTimeoutModal
          getRemainingTime={getRemainingTime}
          modal={{
            open: promptBeforeLogout,
            onContinue: continueSession,
            onSignOut: () => handleIdleLogout('onSignOut'),
          }}
        />
      )}
    </>
  );
};

export default Layout;
