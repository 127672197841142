import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useRouter } from 'next/router';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { ISurveyPostMessage } from '../../PreScreenerQuestionnaire/Survey.types';
import { addScreenerOutput, IScreenerOutput } from '../../../features/screenerOutputSlice';
import { storeCDPData } from '../../../features/cdpDataSlice';
import { AppDispatch, RootState, store } from '../../../store/store';
import { post, put } from '../../../lib/utils/axiosFetcher';
import {
  transformDataToSaveOutput,
  transformDataToSaveMain,
} from '../../../transformers/prescreener.transform';
import * as cdpService from '../../../services/CdpService';
import { linkType } from '../../PreScreenerQuestionnaire/Survey.types';
import {
  CDP_EVENTS,
  JOURNEYS,
  JOURNEY_STATUS,
  PATIENT_TYPE,
  JOURNEYS_ID_MAPPER,
} from '../../../constants';
import { trackEvent } from '../../../lib/utils/gtmService';
import { fetchNextPossiblePathAndData } from '../../../services/CdpService';
import { post as cdpPost } from '../../../lib/utils/axiosCDPFetcher';
import { JOURNEY_STEP, DEFAULT_SCREENER_TYPE, SCREENER_STATUS } from '../../../constants';
import {
  ENROLLMENT_EXPIRY_TIME_FALLBACK,
  getEnrollmentId,
  getOrCreateEnrollmentId,
} from '../../../lib/utils/enrollmentUtil';
import { getCookieExpiryTime } from '../../../lib/utils/enrollmentUtil';
import { checkReturningUserInScreener } from '../../../services/ReturningUserService';
import useCustomErrorModal from '../../../lib/hooks/useCustomErrorModal';
import usePatientErrorModal from '../../../lib/hooks/usePatientErrorModal';
import { post as postToDB } from '../../../lib/utils/axiosFetcher';
import {
  addpotentialParticipantId,
  resetPotentialId,
} from '../../../features/potentialParticipantDetailsSlice';
import { getQuery, getQueryParams, getSortingArray, stringToGUID } from '../../../lib/utils/common';
import { setCookie, getCookie } from 'cookies-next';
import getReferralId from '../../../lib/utils/getReferralId';
import { CR_ROOT_PATH_ID, SMA_TEMPLATE_ID } from '../../../lib/constant';
import axios from 'axios';
import {
  IConditionListProps,
  setAttemptStudies,
  setConditionListFromSurvey,
  setNoOfAttemptToStore,
  setPostDataFromScreener,
  setSettingDetails,
  setLastQuestionSubmitted,
} from '../../../features/multiScreenerSlice';
import { has, isEmpty } from 'lodash';
import { getStudyLocation } from '../../StudyLocation/helper';
import { showLoader, hideLoader } from '../../../features/loaderSlice';
import { CR_SITE } from '../../../lib/constant';

export interface UIIframeProps {
  src: string;
  id?: string;
  title?: string;
  testId?: string;
  width?: string | number | undefined;
  height?: string | number | undefined;
  messageToPost?: ISurveyPostMessage;
  nextPageUrl?: {
    value: linkType;
  };
  enableMultiConditionRouting?: boolean;
  setIsShowLoader: (val: boolean) => void;
}

export interface IGetSitesProps {
  siteRootPath: string;
  protocolSettingId: string;
  siteCount: string;
  eligibleProtocols: string[];
  zipCode: string;
  siteSettingsId?: string;
}

export const StIframe = styled('iframe')(() => ({
  overflow: 'hidden',
  border: 0,
  alignSelf: 'center',
  width: '100%',
}));
const MAX_RETRY_COUNT = 3;
export const UIIframe = ({
  src,
  id,
  title,
  testId,
  messageToPost,
  //nextPageUrl,
  width = '100%',
  height, // Send fixed height if iframe content is not dynamic ,ie, fixed content
  setIsShowLoader,
  ...props
}: UIIframeProps): React.ReactElement => {
  const router = useRouter();
  const { query, locale, defaultLocale } = router;
  const ref = useRef<any>();
  const surveyResponseRef = useRef<any>();
  const [dynamicHeight, setHeight] = useState<string | number | undefined>(400);
  // Getting survey origin
  const arrOfSurveyUrl = src && src.split('/');
  const screenerID = arrOfSurveyUrl[4];
  const surveyOrigin = arrOfSurveyUrl[0] + '//' + arrOfSurveyUrl[2];
  const dispatch = useDispatch<AppDispatch>();
  const [screenerResponseID, setScreenerSessionId] = useState('');
  const { sitecoreContext } = useSitecoreContext() as any;
  const language = sitecoreContext?.language || 'en';
  const isScreenerAttempted = useRef<boolean>(false);
  const cohortAdditionalParameters =
    sitecoreContext?.siteSettings?.cohortAdditionalParameters?.value || '';
  const siteSettings: any = sitecoreContext?.siteSettings;
  const journeyType = useSelector((state: RootState) => state?.cdpData?.journeyType);

  const customErrorMessageModel: any = sitecoreContext?.route?.fields?.customErrorMessageModel;
  const patientExistErrorMessageModel: any =
    sitecoreContext?.route?.fields?.patientExistErrorMessageModel;
  const tenantKey = siteSettings?.tenantKey?.value || '';

  const returningUserTimeInterval = siteSettings?.returningUserTimeInterval?.value;
  const returningUserTimeValue = siteSettings?.returningUserTimeValue?.value;

  const potentialParticipantDetailsFromStore = useSelector(
    (state: RootState) => state.potentialParticipantDetails
  );
  const isAuthenticated: boolean = useSelector(
    (state: RootState) => state.authentication.isAuthenticated
  );

  const siteName = sitecoreContext?.site?.name;

  const queryParamsData = useSelector((state: RootState) => state?.queryParams);
  const invitedSiteQueryParamName = siteSettings?.invitedSiteQueryParamName?.value as string;
  const invitedProtocolQueryParamName = siteSettings?.invitedProtocolQueryParamName
    ?.value as string;

  const invitedSite =
    (invitedSiteQueryParamName &&
      (query?.[invitedSiteQueryParamName] || queryParamsData?.[invitedSiteQueryParamName])) ||
    '';
  const invitedProtocol =
    (invitedProtocolQueryParamName &&
      (query?.[invitedProtocolQueryParamName] ||
        queryParamsData?.[invitedProtocolQueryParamName])) ||
    '';

  const journeyTypeItemId = sitecoreContext?.siteSettings?.journeyType?.id;
  const conditionListFromSurvey: IConditionListProps[] =
    useSelector((state: RootState) => state?.multiScreener?.conditionListFromSurvey) || [];

  const geoLocationState = useSelector((state: RootState) => state.geolocation);
  const cdpState = useSelector((state: RootState) => state.cdpData);
  const contactInfoState = useSelector((state: RootState) => state.contactInfo);
  const contactInfo = useSelector((state: RootState) => state.contactInfoDetails.contactInfo);
  const updatePpidRef = useRef<any>(false);
  const removeQueryParamList = siteSettings?.RemoveQueryParamList?.value?.split('&');
  const retainQueryParamList = siteSettings?.RetainQueryParamList?.value?.split('&');
  const saveSurveyMain = async (screenerResponseID: string) => {
    let response;
    let retryAttempts = 0;

    while (retryAttempts < MAX_RETRY_COUNT) {
      try {
        response = await saveSurveyMainRetry(screenerResponseID);
        return response; // Success, return the response
      } catch (_e: any) {
        console.log(
          `Error while saving screener main data, attempt ${retryAttempts + 1} with error ${
            _e?.message
          }`
        );
        retryAttempts += 1;
        if (retryAttempts >= MAX_RETRY_COUNT) {
          console.log('All retry attempts failed.');
          return; // Exiting after max retries
        }
        // Wait for 2 second before retrying
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    }
  };

  const saveSurveyMainRetry = async (screenerResponseID: string) => {
    if (potentialParticipantDetailsFromStore) {
      const { potentialParticipant_ID, potentialId } = potentialParticipantDetailsFromStore;
      const transformedData = transformDataToSaveMain(
        potentialParticipant_ID,
        potentialId,
        screenerID,
        screenerResponseID,
        siteSettings?.screenerType?.fields?.name?.value || DEFAULT_SCREENER_TYPE,
        tenantKey,
        getOrCreateEnrollmentId()
      );
      const { data: response } = await post('/Prescreener/Add', transformedData);
      response['screenerResponseID'] = screenerResponseID;
      return response;
    }
    return;
  };

  const getSiteSelectionList = async (sitesProps: IGetSitesProps) => {
    const queryParams: any = {
      SiteRootPath: sitesProps.siteRootPath,
      SiteSettingsId: sitesProps.siteSettingsId || sitecoreContext?.siteSettingsId,
      Language: sitecoreContext?.language,
      EligibleProtocols: sitesProps?.eligibleProtocols?.join(',') || '',
      InvitedProtocol: '',
      InvitedSite: '',
      isSitesRequired: 'true',
      Latitude: '',
      Longitude: '',
      PatientAddress: '',
      PatientCity: '',
      PatientState: '',
      SiteCount: sitesProps?.siteCount || '1',
      ProtocolSettingsId: sitesProps.protocolSettingId as string,
      ZipCode: sitesProps?.zipCode || '',
      IsSiteDetailsForHomePage: has(sitesProps, 'IsSiteDetailsForHomePage') ? false : true,
    };

    if (!has(sitesProps, 'IsSiteDetailsForHomePage')) {
      queryParams['EthnicityValue'] = '1';
    }

    try {
      const sites = await getStudyLocation(queryParams);
      return sites;
    } catch (error) {
      return [];
    }
  };

  const { mutate: addMain } = useMutation('screener-main', saveSurveyMain, {
    onSuccess: async (data) => {
      try {
        await updateScreenerDetailsinCDP(data.screenerResponseID, false);
        setTimeout(() => {
          setCookie('journeyStep', JOURNEY_STEP.SCREENER, {
            expires: getCookieExpiryTime(
              siteSettings?.resumeEnrollmentExpirationDays?.value || ENROLLMENT_EXPIRY_TIME_FALLBACK
            ),
            secure: true,
          });
        }, 5500);
      } catch (_e: any) {}
    },
    onError: async () => undefined,
    onSettled: async () => {
      //   queryClient.invalidateQueries(''); // invalidate any get query for screener data
    },
  });

  const saveSurveyOutput = async (data: IScreenerOutput) => {
    if (potentialParticipantDetailsFromStore) {
      dispatch(showLoader());
      const { potentialParticipant_ID, potentialId } = potentialParticipantDetailsFromStore;
      const transformedData = transformDataToSaveOutput(
        potentialParticipant_ID,
        potentialId,
        screenerID,
        screenerResponseID,
        data,
        siteSettings?.screenerType?.fields?.name?.value || 'Alchemer',
        cohortAdditionalParameters,
        tenantKey,
        getOrCreateEnrollmentId()
      );
      const { data: response } = await put('/Prescreener/Update', transformedData);
      return response.data;
    }
  };
  const transformDataScreenerDetails = (
    screenerID: string,
    screenerResponseID: string,
    data: IScreenerOutput,
    zipCode: string,
    isUpdatePreScreener: boolean // to identify whether its screener Main or update in returning user
  ): any => {
    const { pSec, patientType, lastQuestion } = data || {};
    const screenerOutcomeParams: any = [];
    if (data) {
      for (const [key, val] of Object.entries(data)) {
        //pdfContent data from screener is not needed on CDP
        if (key !== 'pdfContent') {
          screenerOutcomeParams.push({
            key,
            value: val == null || val == undefined ? '' : val.toString(),
          });
        }
      }
    }

    let defaultPatientType = patientType;
    if (patientType === '' || patientType === undefined || patientType === null) {
      defaultPatientType = siteSettings?.defaultPatientType?.value;
      if (lastQuestion) {
        trackEvent(
          {
            event: 'Participant Type identified',
            participantType: defaultPatientType,
          },
          siteName === CR_SITE ? true : false
        );
      }
    }
    const payLoadJson = {
      participantType: lastQuestion ? defaultPatientType : null,
      journeyStep: JOURNEY_STEP.SCREENER,
      screenerId: screenerID,
      screenerResponseID: screenerResponseID,
      patientReferralStatus: cdpState?.patientReferralStatus,
      //TODO: Check for screener type
      screenerType: siteSettings?.screenerType?.fields?.name?.value || 'Alchemer',
      screenerStatus: pSec ? SCREENER_STATUS.QUALIFIED : SCREENER_STATUS.DISQUALIFIED,
      screenerCompleted: lastQuestion ? 'true' : 'false',
      screenerOutcomeParams,
      JourneyStatus: 'partial',
      zipCode: journeyType === JOURNEYS.JOURNEY_01 && zipCode ? zipCode : '',
      // added to handle returning user
      returningUserRequestModel: {
        email: contactInfoState?.patient?.emailAddress,
        participantFirstName: contactInfoState?.patient?.firstName,
        participantLastName: contactInfoState?.patient?.lastName,
        participantDateOfBirth: contactInfoState?.patient?.dateofbirth,
        currentScreenerStatus: pSec ? SCREENER_STATUS.QUALIFIED : SCREENER_STATUS.DISQUALIFIED,
        qualifiedProtocol: pSec ? pSec : '',
        isUpdatePreScreener,
      },
    };
    return payLoadJson;
  };

  const updateScreenerDetailsinCDP = async (id: string, isUpdatePreScreener: boolean) => {
    const transformedData = transformDataScreenerDetails(
      screenerID,
      id,
      surveyResponseRef.current,
      geoLocationState?.location?.zipcode,
      isUpdatePreScreener
    );

    // const { potentialParticipant_ID } = potentialParticipantDetailsFromStore;
    const URL = `/api/contacts/AddPrescreenerToDataExtension/${getCookie(
      'bx_guest_ref'
    )}?guestType=${cdpState?.guestType || 'visitor'}`;
    try {
      await cdpPost(URL, transformedData);
    } catch (_e) {}
  };

  const updatePotentialParticipantId = async () => {
    try {
      const { data: response } = await post(
        `/Participant/UpdatePotentialParticipantId?potentialId=${
          potentialParticipantDetailsFromStore?.potentialId
        }&potentialParticipantId=${getCookie('bx_guest_ref')}`
      );
      return response;
    } catch (_e: any) {}
  };

  const { mutate: updatePpId } = useMutation(
    'update-potential-participantId',
    updatePotentialParticipantId,
    {
      onSuccess: () => undefined,
      onError: () => undefined,
      onSettled: () => {
        //   queryClient.invalidateQueries(''); // invalidate any get query for screener data
      },
    }
  );

  const { mutate: addOutput, isLoading } = useMutation('screener-output', saveSurveyOutput, {
    onSuccess: async () => {
      try {
        // update existing record in db
        if (updatePpidRef.current) {
          updatePpId();
        }
        await updateScreenerDetailsinCDP(screenerResponseID, true);
        const experienceId = process.env.NEXT_PUBLIC_CDP_EXPERIENCE_SCREENER_ID;
        const data = await fetchNextPossiblePathAndData(
          experienceId,
          false,
          false,
          sitecoreContext?.site?.name
        );
        //Virtual Site logic
        // const { potentialParticipant_ID } = potentialParticipantDetailsFromStore;
        if (journeyType === JOURNEYS.JOURNEY_03 && data?.isVirtual) {
          const sitesData = data?.sitesData.length > 0 ? data?.sitesData[0] : {};
          const url = typeof window !== 'undefined' ? window.location.href : null;
          const baseUrl = url ? location.protocol + '//' + location.host : null;
          const { potentialParticipant_ID } = potentialParticipantDetailsFromStore;
          const { patientId } = contactInfo || {};

          await cdpService.updateIsVirtualinCDP(
            getCookie('bx_guest_ref') as string,
            cdpState?.guestType || 'visitor', // TODO: Confirm if it is Visitor or customer
            data.isHumanAPIEnabledForVirtual,
            data?.sitesData,
            potentialParticipantDetailsFromStore?.potentialId,
            potentialParticipant_ID,
            patientId
          );

          const constructedSitesData = {
            //id: 0, //not needed
            // protocolInfoId: 0, //not needed
            tenantKey,
            enrollmentId: getOrCreateEnrollmentId(),
            siteInfo: {
              siteCode: sitesData.siteCode,
              siteAddress: sitesData.siteAddress,
              siteCity: sitesData.siteCity,
              siteCountry: sitesData.siteCountry,
              siteName: sitesData.siteName,
              contactPhoneNumber: sitesData.contactPhoneNumber,
              siteZipCode: sitesData.siteZipCode,
              siteState: sitesData.siteState,
              warmTransferNumber: sitesData.warmTransferNumber,
              econsentUrl: sitesData?.eConsentUrl,
              piname: sitesData.piName,
              isSiteConfirmed: true,
              protocolInfo: {
                potentialId: potentialParticipantDetailsFromStore?.potentialId,
                patientId: patientId, // ui check
                potentialParticipantId: potentialParticipant_ID, //ui check
                protocol: sitesData.protocol,
                //TODO: these 2 fields will be used in future as per disscussion with backend team
                //keeping it empty string until UI have these
                sponsorId: '', // need details on this
                studyId: '', //need details on this
                studyName: sitesData.studyName,
                projectCode: sitesData.projectCode, //
                nctid: sitesData.nctid,
                sponsor: sitesData.sponsor,
                indication: sitesData.indication,
                tenantKey,
                enrollmentId: getOrCreateEnrollmentId(),
              },
            },
            baseUrl,
            isKiElementEnabled: sitesData.isSiteKiElementsEnabled, // required for KIE
            isKiElementsSponsorConsentEnabled: false, // required for KIE
            isUserOptedKiElementConsent: '', // required for KIE
            isLifeLinkEnabled: sitesData.isLifeLinkEnabled,
            isUserOptedLifeLinkConsent: '',
            isSponsorLifeLinkEnabled: false, // need to send this value once LL integration id done
            appointmentStatus: '',
            referralId: getReferralId(getOrCreateEnrollmentId(), sitesData.siteCode),
            isHumanAPIEnabled: sitesData.isHumanAPIEnabled || false,
          };

          const response = await post(
            '/SiteSelection/AddOrUpdatePatientSiteDetails',
            constructedSitesData
          );
          if (!response) {
            throw new Error('Error in updating Virtual Sites Data');
          }
        }
        //storing cdp data, so can be used in next step
        dispatch(storeCDPData(data));
        if (data?.nextStepUrl) {
          setCookie('journeyStep', JOURNEY_STEP.SCREENER, {
            expires: getCookieExpiryTime(
              siteSettings?.resumeEnrollmentExpirationDays?.value || ENROLLMENT_EXPIRY_TIME_FALLBACK
            ),
            secure: true,
          });

          await router.push(
            {
              pathname: data?.nextStepUrl,
              query: getQuery(query, retainQueryParamList, removeQueryParamList),
            },
            undefined
          );
          dispatch(setLastQuestionSubmitted(false));
        }
      } catch (error) {
        //TODO: CDP call failed need to check
      }
    },
    onError: () => {
      const extensionData = {
        tenantKey: tenantKey,
        screenerType: siteSettings?.screenerType?.fields?.name?.value || 'Alchemer',
        journeyType: journeyType as string,
      };
      cdpService.event(CDP_EVENTS.SCREENER_OUTPUT_ERROR, extensionData);
    },
    onSettled: () => {
      // Navigate as per user journey once data is saved in database
      //adding prefix for navigation
      // const redirectPathFromScreener = NavigateAsPerUserJourney(surveyResponseRef.current);
      // let newPath = redirectPathFromScreener;
      // if (nextPageUrl?.value?.href && nextPageUrl?.value?.href !== '') {
      //   newPath = nextPageUrl?.value?.href + redirectPathFromScreener;
      // }
      // router.push(newPath);
      //   queryClient.invalidateQueries(''); // invalidate any get query for screener data
    },
  });

  const scrollToQuestionSection = (isPreselect = false) => {
    const itemOffset = document.getElementById('pre-screener-wrapper')?.offsetTop;
    if (itemOffset !== undefined && itemOffset !== null) {
      window.scroll({
        top: isPreselect ? itemOffset + 30 : itemOffset,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const resetCookies = (isMulti = false) => {
    setCookie('enrollmentID', null, {
      expires: getCookieExpiryTime(0),
    });
    setCookie('journeyStatus', '', {
      expires: getCookieExpiryTime(0),
    });
    setCookie('journeyStep', '', {
      expires: getCookieExpiryTime(0),
    });
    setCookie('journeyType', '', {
      expires: getCookieExpiryTime(0),
      secure: true,
    });
    if (!isMulti && !isAuthenticated) {
      setCookie('bx_guest_ref', '', {
        expires: getCookieExpiryTime(0),
        secure: true,
      });
      const cookieName = 'bid_' + process.env.NEXT_PUBLIC_CDP_CLIENT_KEY;
      setCookie(cookieName, '', {
        secure: true,
        expires: getCookieExpiryTime(0),
      });
    }
  };

  const updatePatientOutcomeDetails = async () => {
    try {
      const state = store.getState();
      const potentialId = state?.potentialParticipantDetails?.potentialId;

      if (potentialId) {
        const payload: any = {
          potentialId: potentialId,
          patientReferralStatus: null,
          journeyStatus: JOURNEY_STATUS.COMPLETED,
          deActivateExistingEnrollment: true,
        };

        const { status: response } = await post(`/Patient/UpdatePatientOutcomeDetails`, payload);
        return response === 200 || false;
      }
    } catch (error) {
      return false;
    }
  };

  const updateCurrentJourneyStepinCDP = async (isMultiTA = false) => {
    try {
      const state = store.getState();
      const potentialParticipant_ID = state?.potentialParticipantDetails?.potentialParticipant_ID;
      const payload = {
        enrollmentId: getOrCreateEnrollmentId(),
        journeyStep: getCookie('journeyStep') as string,
        journeyStatus: isMultiTA ? JOURNEY_STATUS.COMPLETED : 'Re-registrationProhibited',
        isActiveEnrollment: '0',
      };

      if (potentialParticipant_ID) {
        const guestType = cdpState?.guestType || 'visitor';
        const fetchResponse = await cdpService.updateCurrentJourneyInCDP(
          potentialParticipant_ID,
          guestType,
          payload
        );
        return (fetchResponse as any)?.status === 200 || false;
      }
      return false;
    } catch (error) {
      return false;
    }
  };

  const updateParticipantStatusInDB = async () => {
    //TODO: payload
    const payload = {
      potentialId: potentialParticipantDetailsFromStore?.potentialId,
      isActiveEnrollment: '0',
      journeyStatus: 'Re-registrationProhibited',
    };
    const { data: response } = await postToDB('/Participant/UpdateParticipantStatus', payload);
    return response;
  };

  const { mutate: updateParticipantStatus } = useMutation(
    'update-participant',
    updateParticipantStatusInDB,
    {
      onSuccess: async () => {
        try {
          await updateCurrentJourneyStepinCDP();
          resetCookies();
          const queryData = getQueryParams(query);
          let hrefValue: string;
          if (queryData === '') {
            hrefValue = locale === defaultLocale ? `/` : `/${locale}`;
          } else {
            hrefValue = locale === defaultLocale ? `/?${queryData}` : `/${locale}?${queryData}`;
          }
          window.location.href = hrefValue;
        } catch (_e) {}
      },
      onError: () => undefined,
    }
  );

  const callbackLeave = async () => {
    await updateParticipantStatus();
  };

  const callbackClose = () => undefined;

  const { setCustomErrorModal, showCustomErrorModal } = useCustomErrorModal(
    siteSettings,
    customErrorMessageModel,
    callbackLeave,
    callbackClose
  );

  const { setPatientExistModal, showPatientExistErrorModal } = usePatientErrorModal(
    patientExistErrorMessageModel,
    callbackLeave,
    callbackClose
  );

  const performReturningUserCheckOnSubmit = async (screenerResponse: any) => {
    try {
      const { data: res } = await checkReturningUserInScreener(
        true,
        tenantKey,
        screenerResponse,
        returningUserTimeInterval,
        returningUserTimeValue,
        siteSettings?.defaultPatientType?.value
      );
      if (res?.potentialParticipantId) {
        setCookie('bx_guest_ref', res?.potentialParticipantId, {
          expires: getCookieExpiryTime(
            siteSettings?.resumeEnrollmentExpirationDays?.value || ENROLLMENT_EXPIRY_TIME_FALLBACK
          ),
          secure: true,
        });
        const cookieName = 'bid_' + process.env.NEXT_PUBLIC_CDP_CLIENT_KEY;
        setCookie(cookieName, res?.browserId, {
          secure: true,
          expires: getCookieExpiryTime(
            siteSettings?.resumeEnrollmentExpirationDays?.value || ENROLLMENT_EXPIRY_TIME_FALLBACK
          ),
        });
        dispatch(addpotentialParticipantId(res?.potentialParticipantId));
        updatePpidRef.current = true;
      }
      if (res?.isReturningUser === false) {
        return;
      } else {
        if (res?.showCustomErrorMessageModel) {
          // show modal popup
          setCustomErrorModal();
          showCustomErrorModal();
        } else if (res?.showPatientExistModel) {
          // show modal popup
          setPatientExistModal();
          showPatientExistErrorModal();
        }
        return;
      }
    } catch (_e) {
      return;
    }
  };

  const fetchConditionRelatedStudyDetails = async (condition: string, patientType: string) => {
    try {
      const state = store.getState();
      const ConditionItemDataList = state?.multiScreener?.ConditionItems;
      const conditionItem =
        (!isEmpty(condition) &&
          !isEmpty(ConditionItemDataList) &&
          ConditionItemDataList.find((x: any) => x?.key?.value === condition)) ||
        {};

      const payload = {
        siteSettingtemplateId: SMA_TEMPLATE_ID,
        conditionItemId:
          !isEmpty(condition) && !isEmpty(conditionItem) ? stringToGUID(conditionItem?.ItemId) : '',
        journeyTypeItemId: (journeyTypeItemId && `{${journeyTypeItemId.toUpperCase()}}`) || '',
        supportedPatientTypes: patientType && (PATIENT_TYPE as any)?.[patientType?.toUpperCase()],
        language,
        rootItemId: CR_ROOT_PATH_ID,
      };

      const response = await axios.post('/api/conditionRelatedStudy', { ...payload });
      return response?.data || response;
    } catch (error) {
      return { data: { conditionRelatedStudyList: [], localProtocoltemplateResults: [] } };
    }
  };

  const completeNextStepsOfScreenerOutput = async (ev: MessageEvent<IScreenerOutput>) => {
    dispatch(showLoader());
    if (journeyType === JOURNEYS.JOURNEY_03) {
      await performReturningUserCheckOnSubmit(ev.data);
    }

    dispatch(addScreenerOutput(ev.data));
    surveyResponseRef.current = ev.data;
    await addOutput(ev.data);
    // Navigate as per user journey
    const eventData = {
      channel: 'WEB',
      currency: 'EUR',
    };

    const extensionData: any = {
      ScreenerId: screenerID,
      screenerResponseID: screenerResponseID,
      PatientType: ev.data.patientType,
      PSec: ev.data.pSec,
      RedirectTo: ev.data.redirectTo,
      ET: ev.data.et,
      screenerStatus: ev.data.pSec ? 'qualified' : 'disqualified',
    };

    cdpService.event(CDP_EVENTS.SCREENER_OUTPUT, eventData, extensionData);
    dispatch(hideLoader());
  };

  const getPrefredLanguage = () => {
    let lang = language;
    const ln = (getCookie('taEprLanguage') as string) || '';
    if (!isEmpty(ln) && !isEmpty(JSON.parse(ln)) && !isEmpty(Object.values(JSON.parse(ln)))) {
      lang = Object.values(JSON.parse(ln));
    }
    return lang || language;
  };

  const scrollToScreener = () => {
    const itemOffset = document.getElementById('pre-screener-wrapper')?.offsetTop;
    const margin = 20;
    if (itemOffset) {
      window.scroll({
        top: itemOffset - margin,
        left: 0,
        behavior: 'smooth',
      });
    }
  };

  const getNextMultiTAEPRUrlBasedOnJounreyType = (conditionRelatedStudyList) => {
    const matchingStudyAsPerJourneyType = conditionRelatedStudyList?.filter(
      (studyDetails: any) => JOURNEYS_ID_MAPPER[studyDetails?.journeyType?.value] === journeyType
    );
    return matchingStudyAsPerJourneyType?.[0]?.['parent']?.['parent']?.['name'];
  };

  const multiRoutingCycleTrigger = async (ev: MessageEvent<IScreenerOutput>) => {
    try {
      dispatch(setPostDataFromScreener([ev.data]));
      const journeyId = journeyType === JOURNEYS.JOURNEY_02 ? 2 : 1;
      const conditions =
        (!isEmpty(ev.data?.genericAnswers?.Cval) && ev.data.genericAnswers.Cval?.split(',')) || [];
      if (!isEmpty(conditions)) {
        const visitedConditions: string[] =
          (!isEmpty(conditionListFromSurvey) &&
            conditionListFromSurvey?.filter((x) => x.isVisited)?.map((b) => b.name)) ||
          [];

        const conditionFromStore: string[] =
          (!isEmpty(conditionListFromSurvey) && conditionListFromSurvey?.map((b) => b.name)) || [];

        const sortedCondition: string[] = getSortingArray([
          ...new Set([...conditions, ...conditionFromStore]),
        ]);
        const uniqueSortedCondition: string[] = sortedCondition.filter(
          (x: any) => !visitedConditions.includes(x)
        );
        let counter = 0;
        if (!uniqueSortedCondition?.length) return false;
        for (let index = 0; index < uniqueSortedCondition.length; index++) {
          const condition = uniqueSortedCondition[index];
          try {
            counter++;
            const response = await fetchConditionRelatedStudyDetails(
              condition,
              (ev.data as any)?.patientType
            );
            const conditionRelatedStudyList =
              response?.conditionRelatedStudyList?.filter((a) => a) || [];
            const localProtocoltemplateResults =
              response?.localProtocoltemplateResults?.filter((a) => a) || [];
            dispatch(
              setConditionListFromSurvey([
                {
                  name: condition,
                  isVisited: true,
                },
              ])
            );
            if (!isEmpty(conditionRelatedStudyList) && conditionRelatedStudyList?.length > 0) {
              const dynamicJourneyCondition = conditionRelatedStudyList.filter(
                (a: any) => a?.enableDynamicJourney?.value
              );
              const state = store.getState();
              const attemptStudies = state?.multiScreener?.attemptStudies || [];
              const visitCount = state?.multiScreener?.noOfAttempt || 0;
              const url =
                (!isEmpty(dynamicJourneyCondition) &&
                  dynamicJourneyCondition?.[0]?.['parent']?.['parent']?.['name']) ||
                getNextMultiTAEPRUrlBasedOnJounreyType(conditionRelatedStudyList);
              const settingId =
                (!isEmpty(dynamicJourneyCondition) && dynamicJourneyCondition?.[0]?.['id']) ||
                conditionRelatedStudyList?.[0]?.['id'];
              const attemptStudiesQuery = attemptStudies
                ?.map((a: any) => a?.toLowerCase())
                ?.join(',');
              const isExist =
                (isEmpty(attemptStudiesQuery) && false) ||
                (!isEmpty(attemptStudiesQuery) &&
                  !isEmpty(url) &&
                  conditionRelatedStudyList.some((x: any) =>
                    attemptStudiesQuery.includes(x?.parent?.parent?.name?.toLowerCase())
                  )) ||
                false;
              const currentRoute = window?.location?.pathname;
              const isDiffRoute = !currentRoute?.includes(url);
              const protocolObj = localProtocoltemplateResults?.find(
                (x: { path: string; id: string }) => x.path.includes(url)
              );
              const protocolList =
                conditionRelatedStudyList?.[0]?.['protocolList']?.['jsonValue'] || [];
              const siteObj = {
                siteRootPath: stringToGUID(
                  conditionRelatedStudyList?.[0]?.['parent']?.['parent']?.['id']
                ) as string,
                protocolSettingId: stringToGUID(protocolObj?.id) as string,
                siteCount: conditionRelatedStudyList?.[0]?.['siteCount']?.['value'],
                eligibleProtocols: protocolList?.map((x: any) => x.name) || [],
                siteSettingsId: (settingId && (stringToGUID(settingId) as string)) || '',
                zipCode: (ev?.data as IScreenerOutput | undefined)?.zipCode as string,
                IsSiteDetailsForHomePage: false,
              };

              const availableSites = await getSiteSelectionList(siteObj);
              if (!isEmpty(availableSites) && availableSites?.length) {
                dispatch(showLoader());
                const dataResponse = await updateCurrentJourneyStepinCDP(true);
                const outComeResponse = await updatePatientOutcomeDetails();
                dispatch(hideLoader());
                if (url && dataResponse && outComeResponse && !isExist && isDiffRoute) {
                  dispatch(setAttemptStudies([...attemptStudies, currentRoute]));
                  resetCookies(true);
                  const cookie_expiry = new Date();
                  cookie_expiry.setDate(cookie_expiry.getDate() + 10e5);
                  setCookie('epr-name', `/${language}/${url}`, {
                    secure: true,
                    expires: cookie_expiry,
                  });
                  dispatch(setSettingDetails(conditionRelatedStudyList));
                  dispatch(setNoOfAttemptToStore((visitCount as number) + 1));
                  dispatch(addpotentialParticipantId(''));
                  dispatch(resetPotentialId());
                  const queryParams = !isEmpty(dynamicJourneyCondition)
                    ? '?journeyId=' + journeyId
                    : '';
                  const taEprLanguage: any = {};
                  const preferredLanguage = getPrefredLanguage();
                  taEprLanguage[url] = preferredLanguage || 'en';
                  setCookie('taEprLanguage', JSON.stringify(taEprLanguage), {
                    expires: cookie_expiry,
                    secure: true,
                  });
                  await router.push(`/${url}${queryParams}`);
                  setTimeout(scrollToScreener, 2000);
                  dispatch(setLastQuestionSubmitted(false));
                  dispatch(hideLoader());
                  return true;
                }
                if (counter >= uniqueSortedCondition.length) return false;
              }
            } else {
              if (counter >= uniqueSortedCondition.length) return false;
            }
          } catch (error) {
            return false;
          }
        }
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  // Method to process response from survey
  const processSurveyResponse = useCallback(
    async (ev: MessageEvent<IScreenerOutput | string>) => {
      const state = store.getState();
      const counter = state?.multiScreener?.noOfAttempt || 0;
      if (typeof ev.data !== 'object') {
        const iframeHeight = ev && ev.data && ev.data.split('::')[0];
        const screenerResponseID = ev && ev.data && ev.data.split('::')[5];
        const currentQuestion = ev && ev.data && ev.data.split('::')[2];
        if (screenerResponseID) {
          if (isScreenerAttempted.current === false) {
            isScreenerAttempted.current = true;
            setScreenerSessionId(screenerResponseID);
            addMain(screenerResponseID);
            const extensionData = {
              ScreenerId: screenerID,
              screenerResponseID: screenerResponseID,
            };

            cdpService.event(CDP_EVENTS.SCREENER_SECOND_QUESTION, extensionData);
          }
        }
        if (isScreenerAttempted.current) {
          if (counter > 0 && screenerResponseID) {
            scrollToQuestionSection(true);
          } else {
            scrollToQuestionSection();
          }
        }
        if (iframeHeight !== 'submitQuestion') {
          if (currentQuestion === '2') {
            trackEvent(
              {
                event: 'Started Prescreener',
              },
              siteName === CR_SITE ? true : false
            );
          }
          trackEvent(
            {
              event: `Question${currentQuestion} Displayed`,
              formId: id,
              sguid: getEnrollmentId(),
            },
            siteName === CR_SITE ? true : false
          );
          // add 30 since iframe is returning incorrect offsetHeight for some questions
          setHeight(+iframeHeight + 30);
        } else {
          trackEvent(
            {
              event: `Question${currentQuestion} Submited`,
              formId: id,
              sguid: getEnrollmentId(),
            },
            siteName === CR_SITE ? true : false
          );
        }
      } else {
        // Check if we are on last question
        if (ev.data?.lastQuestion) {
          dispatch(setLastQuestionSubmitted(true));
          if (ev.data?.pSec) {
            if (
              props?.enableMultiConditionRouting &&
              journeyType !== JOURNEYS.JOURNEY_03 &&
              isEmpty(invitedSite) &&
              isEmpty(invitedProtocol) &&
              (counter as number) < 3
            ) {
              const siteObj = {
                siteRootPath: sitecoreContext?.siteRootPath as string,
                protocolSettingId: sitecoreContext?.protocolSettingsId as string,
                siteCount: sitecoreContext?.siteSettings?.siteFetchCount?.value || '1',
                eligibleProtocols:
                  sitecoreContext?.siteSettings?.protocols?.map(
                    (x: any) => x?.fields?.protocolId?.value
                  ) || [],
                zipCode: (ev?.data as IScreenerOutput | undefined)?.zipCode as string,
                IsSiteDetailsForHomePage: false,
              };
              dispatch(showLoader());
              const availableSites = await getSiteSelectionList(siteObj);
              if (!isEmpty(availableSites) && availableSites?.length > 0) {
                trackEvent(
                  {
                    event: 'Completed Prescreener - Qualified',
                  },
                  siteName === CR_SITE ? true : false
                );
                await completeNextStepsOfScreenerOutput(ev as MessageEvent<IScreenerOutput>);
              } else {
                dispatch(hideLoader());
                setIsShowLoader(true);
                const result = await multiRoutingCycleTrigger(ev as MessageEvent<IScreenerOutput>);
                setIsShowLoader(false);
                dispatch(showLoader());
                if (result) return;
                if (!result)
                  await completeNextStepsOfScreenerOutput(ev as MessageEvent<IScreenerOutput>);
              }
            } else {
              dispatch(showLoader());
              trackEvent(
                {
                  event: 'Completed Prescreener - Qualified',
                },
                siteName === CR_SITE ? true : false
              );
              await completeNextStepsOfScreenerOutput(ev as MessageEvent<IScreenerOutput>);
            }
          } else {
            if (
              props?.enableMultiConditionRouting &&
              journeyType !== JOURNEYS.JOURNEY_03 &&
              isEmpty(invitedSite) &&
              isEmpty(invitedProtocol) &&
              (counter as number) < 3
            ) {
              dispatch(hideLoader());
              setIsShowLoader(true);
              const result = await multiRoutingCycleTrigger(ev as MessageEvent<IScreenerOutput>);
              setIsShowLoader(false);
              if (result) return;
              if (!result)
                await completeNextStepsOfScreenerOutput(ev as MessageEvent<IScreenerOutput>);
            } else {
              trackEvent(
                {
                  event: 'Completed Prescreener - Disqualified',
                },
                siteName === CR_SITE ? true : false
              );
              await completeNextStepsOfScreenerOutput(ev as MessageEvent<IScreenerOutput>);
            }
          }
        }
      }
    },
    [dispatch, addMain, addOutput]
  );

  useEffect(() => {
    if (!ref.current) {
      ref.current = {};
    }
    ref.current.src = src;
    const messageHandler = (ev: MessageEvent) => {
      // Check origin
      if (ev.origin !== surveyOrigin) return;
      processSurveyResponse(ev);
      // identifyReturningUser();
    };

    if (typeof window === undefined) return;
    window?.addEventListener('message', messageHandler);
    return () => window?.removeEventListener('message', messageHandler);

    // Disabling dependency rule since adding the function as dependency will also add router
    // as a dependency and it will again call this useeffect once router query is created
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, surveyOrigin]);

  const handleIframeLoad = () => {
    const iframe = document.querySelector('iframe');
    const postData = JSON.stringify(messageToPost);
    iframe?.contentWindow?.postMessage(postData, src);
  };

  return (
    <>
      <StIframe
        ref={ref}
        className="iframe"
        frameBorder="0"
        src=""
        id={id}
        title={title}
        data-testid={testId}
        onLoad={handleIframeLoad}
        width={width}
        height={height ? height : dynamicHeight}
        {...props}
      ></StIframe>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
